<template>
  <div class="max-w-5xl mx-auto">
    <h2 class="text-4xl font-bold text-center my-10 font-nunito">Savings Calculator</h2>
    <section class="container grid md:grid-cols-2 grid-cols-1 md:px-5 p-6">
      <div>
        <div class="mb-20">
          <h3 class="text-xl font-semibold font-nunito">1. How many people are you traveling with?</h3>
          <p class="text-gray-700 text-sm my-3 font-nunito">
            Please specify the total number of travelers, including yourself.
          </p>

          <div class="w-full">
            <label for="range-slider" class="block text-lg font-medium text-gray-700 mb-4"></label>
            <input
              id="range-slider"
              type="range"
              min="1"
              max="6"
              step="1"
              v-model="selectedPeople"
              class="w-full border border-red-200 from-red-50 to-red-200 bg-gradient-to-r accent-red-600 rounded-md appearance-none cursor-pointer"
            />
            <div class="flex justify-between mt-4 text-sm text-gray-500">
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span>6+</span>
            </div>
          </div>
        </div>

        <div class="mb-20">
          <h3 class="text-xl font-semibold font-nunito">2. Select Your Trip Duration</h3>
          <p class="text-gray-700 text-sm my-3 font-nunito">
            How many days do you plan to spend discovering the wonders of Thailand?
          </p>

          <div class="w-full">
            <label for="range-slider" class="block text-lg font-medium text-gray-700 mb-4"></label>
            <input
              id="range-slider"
              type="range"
              min="1"
              max="10"
              step="1"
              v-model="numberOfDays"
              class="w-full border border-red-200 from-red-50 to-red-200 bg-gradient-to-r accent-red-600 rounded-md appearance-none cursor-pointer"
            />
            <div class="flex justify-between mt-4 text-sm text-gray-500">
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span>6</span>
              <span>7</span>
              <span>8</span>
              <span>9</span>
              <span>10+</span>
            </div>
          </div>
        </div>

        <div class="mb-20">
          <h3 class="text-xl font-semibold font-nunito">4. Choose Your Destinations</h3>
          <p class="text-sm text-gray-700 font-nunito my-3">
            Which cities are on your must-visit list? Select all that apply.
          </p>

          <div class="checkbox-group flex gap-2 flex-wrap">
            <label
              v-for="city in main.cities.filter((i) => i.active)"
              :key="city.id"
              class="checkbox-item cursor-pointer border rounded-md py-2 px-3"
              :class="{ 'bg-red-600 text-white': selectedCities.includes(city.id) }"
            >
              <input type="checkbox" :value="city.id" v-model="selectedCities" class="hidden-checkbox" />
              <div class="option font-nunito">
                {{ city.name }}
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-center relative">
        <div class="p-10 relative">
          <h3 class="font-bold md:text-4xl text-3xl text-center font-nunito z-2">
            You can save upto <br />
            <span class="font-black md:text-6xl text-4xl">{{ $money(savings, 'INR') }}</span>
          </h3>
          <!-- <img
            src="@/assets/images/coin.png"
            alt=""
            v-for="coin in coins"
            class="absolute w-10 right-4"
            :key="coin"
            :style="{ bottom: `${coin * 10}px` }"
          />
          <img
            src="@/assets/images/banknote.png"
            alt=""
            v-for="note in banknotes"
            class="absolute w-32 -right-10"
            :style="{ bottom: `${note * 10}px` }"
          /> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useMainStore } from '@/stores/main';

const main = useMainStore();

const selectedPeople = ref(2);
const numberOfDays = ref(3);
const selectedCities = ref([11]);

const selectedCitiesTotalSaving = computed(() => {
  return selectedCities.value.reduce((acc, cityId) => {
    return acc + main.cityMapping[cityId].saving;
  }, 0);
});

const savings = computed(() => {
  return selectedPeople.value * numberOfDays.value * 0.8 * selectedCitiesTotalSaving.value;
});

const coins = computed(() => {
  return selectedPeople.value;
});

const banknotes = computed(() => {
  return selectedCities.value.length * numberOfDays.value;
});
</script>

<style lang="scss" scoped>
.radio-button {
  cursor: pointer;
  position: relative;
}

.radio-button input[type='radio'] {
  display: none; /* Hide the default radio input */
}

.radio-button .option {
  border: 1px solid #ddd;
  transition: background-color 0.2s, border-color 0.2s;
}

.radio-button input[type='radio']:checked + .option {
  border-color: rgb(220, 38, 38);
  background-color: rgb(220, 38, 38);
  color: #fff;
}

.checkbox-item {
  position: relative;
  display: inline-block;
}

.hidden-checkbox {
  display: none; /* Hide the default checkbox */
}

.tuktuk {
  animation: moveLeft 20s linear infinite;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@keyframes moveLeft {
  from {
    right: 0;
  }
  to {
    right: 100%;
    transform: translateX(-100%); /* Ensures it moves completely off screen to the right */
  }
}
</style>
